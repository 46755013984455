/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Fixtures } from '../../components/tickets';

const Travel = (props) => {

  return (
    <Fixtures
      {...props}
      variant="travel"
    />
  )
}

export default Travel